import * as React from 'react';
import { Link } from 'gatsby';
import './NotFound.scss';
import SVG_ICONS_NOT_FOUND from '../../images/404/svg';
import ROUTES from '../../constants/routes';

const NotFoundContent = () => {
  return (
    <section className="notFound">
      <div className="notFound__container">
        <div className="notFound__cubes">
          <img className="cube" src={SVG_ICONS_NOT_FOUND.SMALL_CUB} alt="cube" />
          <h1>404</h1>
          <img className="cube" src={SVG_ICONS_NOT_FOUND.LARGE_CUB} alt="cube" />
        </div>
        <div className="notFound__content">
          <span>This page does not exist</span>
          <p>You may have mistyped the address or the page may have moved</p>
          <Link to={ROUTES.HOME}>Go to Homepage</Link>
        </div>
      </div>
    </section>
  );
};

export default NotFoundContent;
